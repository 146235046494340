@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');



@font-face {
  font-family: 'kyri_kapsregular';
  src: url('/assets/fonts/kyri_kaps-webfont.woff2') format('woff2'),
      url('/assets/fonts/kyri_kaps-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

$wayred:#e6613e;
$wayblue: #4295CF;
$waygreen: #17AF88;
$waypurple: #8378A3;

$rounded-size: 15px;



* {
  box-sizing: border-box;
  outline:none;
}
*:focus {outline:none !important}

html,
body,
#root {
  width: 100%;
  min-width:  100vw;
  height: 100%;
  min-height:100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  
}

body {
  font-family: -apple-system, system-ui, sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 150%;
  color: white;/* rgba(0, 0, 0, 0.75);*/
  background-color: #e6613e;
  cursor: none;
}

#AudioSuspended{
  z-index: 999999999;
position: absolute;
bottom: 0;
left: 0;
width: 100%;
height: 100px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: left;
padding: 0 5%;

@media (max-width: 580px) {
  bottom:unset;
  top:24px;
}

  h2{
    display: inline;
    font-size: 12px;
    line-height: 16px;
    margin:0;
    

    span{
      margin: 0 5px;
    }
  }
}
.activate-button{
  padding: 5px 10px;
border: 1px solid white;
background: transparent;
color: white;
margin: 20px;


&:hover{
  background: white;
  color: $wayred;
}
}


.bsc-banner{
  background-color: #FFE70E;
  z-index: 9999;
  padding: 8px 20px;
  margin-bottom: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  a:hover{
    p{
      color: $wayred;
    }
  }

  p{
    color: #4395CF;
font-size: 16px;
font-weight: 600;
line-height: 16px;
margin: 0;
text-align: center;
text-transform: uppercase;
  }
}

.overlay {
  position: fixed;

  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #8e4b8e;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &.book-activities-overlay{
    background-color: $waypurple;

    .close-btn{
      background-color: darken($waypurple,20%);
    }
  }

  &.games-overlay{
    background-color: #79B6D8;

    .close-btn{
      background-color: darken(#79B6D8,20%);
    }
  }

  &.recipies-overlay{
    background-color: #DF6674;
    .close-btn{
      background-color: darken(#DF6674,20%);
    }
  }

  &.posters-overlay{
    background-color: #C2659B;
    .close-btn{
      background-color: darken(#C2659B,20%);
    }
  }
  &.bsc-overlay{
    background-color: $waygreen; //#38468A
    background-image: url(/assets/images/rainbow2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    

    .close-btn{
      background-color: darken($waygreen,20%);
    }
  }

  &.more-fun-overlay{
    background-color: #25bac7;
    .close-btn{
      background-color: darken(#25bac7,20%);
    }
  }

  .overlay-content{
    width: 100%;
    height: 100%;
    overflow: scroll;

    .overlay-headline{
      margin: 30px 10px;

      @media (max-width: 580px) {

        margin: 100px 10px 30px;
      }

      h2{
        font-size: 32px;
        line-height: 36px;
      }
    }
    

    .grid-content{
     // display: flex;
     // flex-direction: row;
     // flex-wrap: wrap;
      width: 100%;
      height: auto;
      max-width: 1200px;
      
      //justify-content: space-evenly;
     // padding: 40px 0px;
      //align-items: center;

      margin: 0 auto;

      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      
     // //grid-gap: 1rem;
      row-gap: 10px;
      column-gap: 1rem;
      grid-template-rows: max-content;
    //  grid-auto-rows: 1fr;
      padding: 0 20px 80px;
   



      a{
        margin: 0 auto;
        transform: translateY(0px);
        transition: transform .3s ease-out;
        text-decoration: none;
        

      //  border: 10px solid orange;

     //   height: 400px;
        width: 100%;

        img{
          margin: 0 auto;
            display: block;
        }




        &.vert .grid-item{
         // aspect-ratio: 4/3;
         
         // width: 200px;
       //  flex: 40;
       justify-content: flex-start;

          img{
            width: 75%;
          }
        }

        &.horiz .grid-item{
        //  aspect-ratio: 9/16;
          //width: 300px;
          //flex: 60;
          justify-content: center;

          img{
            width: 100%;
            
          }

         
        }

        &:hover{
          transform: translateY(-5px);

          h3{
            color: yellow;
          }
        }

        .grid-item{
          display: flex;
          flex-direction: column;
          align-items: center;

          justify-content: center;
          width: 100%;
          height: 100%;
          min-height: 510px;

          img{
            max-width: 100%;
            
          }

          h3{
            margin: 30px;
            text-align: center;
            height: 100px;
            
          }
        }
      }

      button{
        display: block;
      }
    }
  }

  .close-btn{
    background: rgb(184, 77, 49);
    width: 56px;
    height: 56px;
    position: absolute;

    background: purple;
    border-radius: 50%;
    margin-left: -4px;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
    right: 20px;
    top: 20px;
    border-radius: 50%;
    margin-left: -4px;
    cursor: pointer;
    border: none;
    color: white;
    z-index: 9;

    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    border: none;
    line-height: 56px;

    &:hover{
      color: yellow;
    }
  
  }
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

.framed{
  overflow: hidden;
  max-width: 100%;
  z-index: 9999999989;
  height: 100vh;
  width: 100vw;
  background-color: white;
  position: absolute;
  transform: scale(.1);
  animation: crescendo .5s ease-in-out;
  animation-fill-mode: forwards;

  .fake-button{
   // border: 1px solid red;
    width: 100px;
    height: 100px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left:0;
    z-index: 99999999899;
  }

}

@keyframes crescendo {
  0%   {transform: scale(.1);}
  100% {transform: scale(1);}
}

h1 {
  font-family: 'kyri_kapsregular', sans-serif;
  font-style: normal;
  font-weight: 900;
  color: white;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 86px;
  line-height: 1em;
  letter-spacing: -4px;
  letter-spacing: 2.25px;
  text-shadow: 0 2px 4px rgba(0,0,0,.5);
  text-align: center;
}

h2 {
  font-family: 'kyri_kapsregular', sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #272730;
  color: white;
  font-size: 62px;
  line-height: 62px;
  letter-spacing: -2px;
  margin-top: 0;
  margin-bottom: 3rem;
  letter-spacing: 2.25px;
  text-shadow: 0 2px 4px rgba(0,0,0,.5);
  text-align: center;
}

h3 {
  font-style: normal;
  font-weight: 600;
  color: #272730;
  color: white;
  font-size: 20px;
  line-height: 20px;
  margin-top: 2rem;
  margin-bottom: 0;
}

h4 {
  font-family: 'kyri_kapsregular', sans-serif;
}

p {
  font-family: 'Jost', system-ui, sans-serif;
  font-weight: 400;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 1rem;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
}

.block{
  width: 100vw;
  height: 100vh;
  //background-color: rgba(0,0,0,.3);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
 // outline: 2px solid orange;
}

.debug{
  display: none;
}
.stats{
//  display: none;
  position: fixed;
  bottom: 0;
  top: inherit !important;
  left: 0;
}
.debugPercent{
  font-family: -apple-system, sans-serif;
  font-size: 24px;
  color: yellow;
  position: absolute;
  top:50px;
  left:0;
  padding: 10px;
}

.vis-hidden{
  opacity:0;
}

.scroller{
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 42px;
  z-index: 999999;
}

.pink{
  background-color: pink;
}

.testControls{
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99;
}

.word-balloon{
 // border: 1px solid black;
  font-family: 'kyri_kapsregular', sans-serif;
  font-size: 14px;
  color: black;
 /* padding: 10px 40px;
  border-top-left-radius: 50% 60%;
  border-top-right-radius: 60% 50% ;
  border-bottom-left-radius: 50% 60% ;
  border-bottom-right-radius: 60% 50%;
*/
 

}
/*
.word-balloon.bubble { 
	background-position: center;
  background-repeat: no-repeat !important;
	background-size: 100% 100%;
  margin: 0 auto;
	text-align: center;
	height: 0;
	box-sizing: content-box;
	line-height: 1;
}
.word-balloon.speech {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/speech-bubble.svg); 
 // width: 25%;
  padding-top: 6%;
	padding-bottom: 6%;
}
*/

.word-balloon {
  top: 50%;
  left: 20%;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 50%;
  z-index: 999999;
  border: 1px solid black;
  max-width: 260px;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bubble-text {
  z-index: 9999;
  /* outline-style: none;*/
}

.balloon-body {
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: -2;
}

.DialogWindow{
  text-align:center;
  line-height: 20px;
}

.tail {
  z-index: -1;

  position: absolute;
  height: 0;
  width: 0;

  top: 50%;
  left: 20%;
  transform: rotate(-50deg) scaleY(-1);
  /* box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);*/
  /*  animation: rotation 5s infinite linear;*/
  transform-origin: 50% 0%;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 80px solid #fff;
}

.tail-shadow {
  z-index: -3;

  position: absolute;
  height: 0;
  width: 0;

  top: 50%;
  left: 20%;
  transform: rotate(-50deg) scaleY(-1) translateX(0px);
  /* box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);*/
  /*  animation: rotation 5s infinite linear;*/
  transform-origin: 50% 0%;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 90px solid #000;
}


.mycursor{
  overflow: hidden;
  

  &::before{
    content:'';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
   // background-image: url('/assets/images/worried-bug.png');
   //@function background-size: 110%;
    
  }
}

.cursorBox{
  // width: 100vw;
   // height: 100vh;
  ///  display: flex;
   // align-items: center;
   // justify-content: center;
   /*
   position: absolute;
   top: 50%;
   left: 10%;
   */
   position: relative;
   width: 100%;
   height: 100%;
  // border: 1px solid orange;
  // z-index: 999999;

  .cursorHolder{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: overflow-wrap;
    justify-content: center;
    align-items: center;
    width: 100%;//80px;
    height: 100%;//80px;
   // border: 1px solid yellow;

    .cursorCircle{
      position: absolute;
      width: 80%;
      height: 80%;
      background: purple;
      border-radius: 50%;
      margin-left: -4px;
      cursor: none;//pointer;
      box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
      will-change: width, height;
    }

    .character{
      width: 100%;
      height: 100%;
      /*
      position: absolute;
      top: 0;
      left:0;
     // background: white;
     // border-radius: 100px;
      background-size: contain;
     
      will-change: transform, opacity;
      */
    }
  }
}

.mobileBug{
  width:60px;
  height: 60px;
  cursor: pointer;
}

.more-menu{
  background-image: url(/assets/images/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
.close-menu{
  background-image: url(/assets/images/plus.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;

  
}

.insta-icon{
  background-image: url(/assets/images/icon-instagram.svg);
 background-repeat: no-repeat;
 background-position: center;
 background-size: 50%;
 position: absolute;
 bottom: 73px;
 right: 12px;
 width: 35px;
 height: 35px;
 background-color: #9f65c2;
 box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.35);
 border-radius: 30px;

 &:hover{
  background-color: #ab86c2;
  cursor: pointer;
 }
}

.clip-circle {
  clip-path: circle(40px at center);
}

.main-logo{
  height: 50vh;
}

.logoBox{
  // width: 100vw;
   // height: 100vh;
  ///  display: flex;
   // align-items: center;
   // justify-content: center;

   cursor: pointer;

  .logoHolder{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: overflow-wrap;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
   // border: 1px solid yellow;

    .logoCircle{
      position: absolute;
      width: 80%;
      height: 80%;
      background: purple;
      border-radius: 50%;
      margin-left: -4px;
      cursor: pointer;
      box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
      will-change: width, height;
    }

    .letter{
      width: 60px;
      height: 60px;
      background: white;
      border-radius: 100px;
      background-size: contain;
      /* border: 1px solid pink;*/
      will-change: transform, opacity;
    }
  }
}


.arrow{
  width: 20px;
  height: 20px;
 // background: white;
  border-radius: 100px;
 // background-size: contain;
  background-position: 40% 50%;
  /* border: 1px solid pink;*/
  will-change: transform, opacity;
}

.page {
  padding: 0 15rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.page.right {
  justify-content: flex-end;
  align-items: flex-end;
}

.page .block::before {
}

.half {
  width: 450px;
  max-width: 50%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 4rem;
  grid-template-areas: 'left right' 'content content';
}

.content {
  grid-area: content;
}

.left {
  grid-area: left;
}

.right {
  grid-area: right;
}

@keyframes changewidth {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.transition {
  display: inline-block;
  position: absolute !important;
  transition: all 0.5s ease;
  transition-property: opacity, transform;
}

.transition.vertical[hidden] {
  transition-delay: 0s;
  opacity: 0;
  transform: translate3d(0, -20px, 0) !important;
}

.transition.horizontal[hidden] {
  transition-delay: 0s;
  opacity: 0;
  transform: translate3d(30px, 0, 0) !important;
}
/*
.logo {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 100px;
  width: 60px;
  height: 100px;
  background-image: url('/awv.png');
  background-size: center;
  background-repeat: no-repeat;
  background-size: contain;
}
*/
.menubar{
  position: fixed;
  top: 20px;
  left: 0px;
  z-index: 999;
  width: 100vw;
 
}

.menu{
  color: #F4FF90;
  text-transform: uppercase;
  position: fixed;
}

.menu.left {
  position: absolute;
  top: 4rem;
  left: 4rem;
}

.menu.right {
  position: absolute;
  top: 4rem;
  right: 4rem;
}

.menu.middle {
  position: absolute;
  top: 4rem;
  left: 4rem;
  right: 4rem;
  display: flex;
  justify-content: center;
}


.menu > a {
  color: #F4FF90;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  font-family: 'Jost', system-ui, sans-serif;
  font-weight: 700;
  text-decoration: none;

 
  &:hover, &:focus, &.active {

  
    transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
    color: white;
    animation: gelatine 0.5s 1;
  
  }
}

.menu > a:not(:last-child) {
 // border-right: 1px solid rgba(0, 0, 0, 0.5);
}


/// mobile menu /////
.mobile-menu {
  position: absolute;
  background-color: #8e4b8e;//lighten($waypurple,10%);
  color: #fff;
  z-index: 30;
}



.mobile-menu--full {
  width: 100%;
  height: 100dvh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu-list {
  padding: 0;
  list-style: none;
}


.mobile-menu-list-item--full {
  font-size: 2.8em;
  margin: 40px 0;
}


.mobile-menu-list-item--right {
  margin: 24px 0;
}
/*
.mobile-menu a {
  text-decoration: none;
  color: #e8daff;
}

.mobile-menu a:hover,
.mobile-menu a:focus {
  color: #fff;
}
*/
.mobile-menu a {
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  font-family: 'Jost', system-ui, sans-serif;
  font-weight: 700;
  text-decoration: none;
  color: #F4FF90;
 
  &:hover, &:focus {

    color: white;
  
  
  }
}

.category-bar{
  position: absolute;
  bottom: 50px;
  left: 50%;
  border: 1px solid white;
  transform: translate(-50%, 0%);

  height: 40px;
  width: 240px;
  overflow: hidden;
  border-radius: 10px;
  transition: 
    height 0.3s cubic-bezier(0.68,-0.55,0.27,1.55), 
    border 0.3s ease-out;
  

  &::before{
    content:'';

  }

  &:hover{
   // height: 270px;
   //background-color: #F4FF90 !important;
   border: 1px solid #F4FF90 !important;
   

   .category-instruction{
    //transform: scale(1.2);
   }
  
  }

  &.is-expanded{
    height: 320px;
  }

  .category-dropdown{
    display: flex;
    flex-direction: column;

    a{
      margin: 0 auto;
      padding: 5px 0;
     // color: #ffffff;
      color: #F4FF90;
      //padding-left: 1rem;
      //padding-right: 1rem;
      cursor: pointer;
      font-family: "Jost", system-ui, sans-serif;
      font-weight: 300;
      font-size: 16px;
      letter-spacing: 1px;
      text-decoration: none;
      text-transform: uppercase;
      width: 100%;
      text-align: center;

      &:hover{
       // color: #F4FF90;
        color: #ffffff;
      }

      &.category-instruction{
       // color: #F4FF90;
      // transform: scale(1);
      // transition: transform 0.3s cubic-bezier(0.68,-0.55,0.27,1.55);
        color: #ffffff;
        font-weight: 700;
        margin-bottom: 15px;
      }
    }
  }
}


.jumbo {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate3d(-50%, -40%, 0);
  display: flex;
  flex-direction: column;
  align-items: left;
  min-width: 380px;
  width: 50%;
 // animation: 1s ease-out 0s 1 slideInFromBottom;

  /////
}

.announcement{
    color: #FFFFFF;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
    font-family: 'Jost', system-ui, sans-serif;
    font-weight: 700;
    padding: 0;

    a{
      color: #F4FF90;
      text-decoration: none;
    }
}

@keyframes slideInFromBottom {
  0% {
    transform: scale(1.2) translate3d(-50%, -40%, 0);
    opacity:0;
  }
  100% {
    transform: scale(1) translate3d(-50%, -40%, 0);
    opacity:1;
  }
}

.jumbo h1 {
  width: 0;
}

.jumbo h3{
  font-family: 'kyri_kapsregular', sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #272730;
  color: white;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -2px;
  margin-top: 0;
  margin-bottom: 3rem;
  letter-spacing: 1px;
  text-shadow: 0 1px 2px rgba(0,0,0,.5);
}

.jumbo p {
  line-height: 200%;
  color: rgba(0, 0, 0, 0.75);
  text-align: left;
  margin-bottom: 1.5rem;
}

.jumbo p > span {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.jumbo p > a {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  background: #fcfabb;
  color: #272730;
  padding: 4px 10px;
  margin-right: 4px;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
  vertical-align: bottom;
  top: 3px;
}

.jumbo button {
  width: 240px;
  border: none;
  border-radius: 5px;
  padding: 1rem 2rem;
  margin: 0;
  margin-top: 1rem;
  text-decoration: none;
  background: black;
  color: white;
  font-family: Varela;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  cursor: pointer;
  text-align: center;
}

.test-label{
  position: absolute;
  top:0;
  left:0;
  display:none;
}


.backPanel{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  z-index: -10;
  transition: background-color .3s ease-out;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  overflow-x: hidden;
}

.gridSpacer{
  display: none;
}

.scrollArea.grid-mode{
  //display: none;

  .gridSpacer{
    display: block;
  //  height: 180vh !important;
  }

  .domContent{
    display: none;
  }

  .spacer{
    height: 50vh !important;
  }
}

.wide-panel{
 // width:100vw;
 // border: yellow 1px solid;

 margin: 0 auto;
  width: 90vw;
  max-width: 1200px;
  //height: auto;
  min-height: 100vh;
 // height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  &.colorize.blue{
    background-color: darken($wayblue,20%);
  }

  &.colorize.green{
    background-color: darken($waygreen,20%);
  }

  &.colorize.purple{
    background-color: darken($waypurple,20%);
  }
}

.split-view{
  justify-content: flex-end;
}

.rounded-corners{
  border-radius: $rounded-size;


  canvas{
    border-radius: $rounded-size;
    max-width: 100%;
    height: auto !important;
  }

  iframe{
    border-radius: $rounded-size;
  }
}



.video-holder{
  background-color: white;
  transition: background-color .3s ease-out;
  border-radius: $rounded-size;
  position: relative;
  position: relative;
  width: 100%;
 // padding-bottom: 56.25%;
  padding-bottom: 40.85%;
  max-width: 860px;
  aspect-ratio: 16/9;

  &.colorize.blue{
    background-color: darken($wayblue,20%);
  }

  &.colorize.green{
    background-color: darken($waygreen,20%);
  }

  &.colorize.purple{
    background-color: darken($waypurple,20%);
  }

  .buttonContainer{
    left: 10%;
    bottom: -50px;

    a{
      cursor: pointer;
    }
  }

  iframe{
    // width: 100%;
     position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
          
   }
}

.image-holder{
  max-width: 600px;
  height: 336px;
  width: 100%;
  border-radius: $rounded-size;
  overflow: hidden;
  
 // transition: all .3s ease-out;
  transition: all 0.5s ease;
  transform: scale(1);

  &:hover{
    transform: scale(1.01)
  }

  img{
    width: 100%;
  }
}

.image-holder-bsc{
  max-width: 800px;
}

.clickable{
  cursor: pointer;
}

.content-holder{
  background-color: white;
  transition: background-color .3s ease-out;
  border-radius: $rounded-size;
  max-width: 860px;
  width: 100%;
  height: 480px;
  border-radius: $rounded-size;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  &.colorize{
    background-color: darken($waygreen,10%);
  }



  &.colorize.blue{
    background-color: darken($wayblue,20%);
  }

  &.colorize.green{
    background-color: darken($waygreen,20%);
  }

  &.colorize.purple{
    background-color: darken($waypurple,20%);
  }

  .buttonContainer{
    left: 40px;
    bottom: -50px;
  }
}

.content-holder.bsc{
  display: flex;
  flex-direction: column;

  h4{
    margin-top: 5px;
   // margin-bottom: 5px;
  }
}

.wide-panel.book-song-panel{

  max-width: 860px;

  .content-holder{
    flex-direction: column;
    padding: 25px;
   // height: auto;

    .image-holder{
     // border: 10px solid yellow;
      max-width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
    }

    .book-song-headline{
      width: auto;
      padding: 20px 0;
      //margin: 0 40px;

        h2{
          font-size: 1.8em;
          line-height: 1.2em;
          text-align: center;
          margin: 0 0 20px 0;

          .title-highlight{
            color: #F4FF90;
          }
        }
    }

    .book-song-info{
      width: auto;
      padding: 20px;
      background-color: aqua;
      //margin: 0 40px;

      a{
        background-color: #F4FF90;
        text-decoration: none;
        padding: 0 4px;

        &:hover{
          background-color: #FFFFFF;
        }
      }
    }

    .buttonContainer{
      left: 10%;
      bottom: -50px;
    }
  }
}

.video-wrapper {
  perspective: 1000px;
}
.fvideo {
  transform: rotateY(-30deg);
}

.roundedVideo{
  border-radius: 20px;
  overflow: hidden;
  transition: transform 1s;
  transform: scale(.8,.8)

}

.roundedVideo.playing{
  transform: scale(1,1);
}

.cardgame{
  width: 25%;
  height: 25%;
}

/*
#root > div {
  position: absolute;
  width: 100vw;
  height: 100vh;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}
*/

.card-holder{
  position: absolute;
  width: 100vw;
  height: 100vh;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  background-color: white;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 45vh;
  max-width: 300px;
  height: 85vh;
  max-height: 520px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}


.book-info{
  width: 50%;
  align-self: end;
 // padding: 80px 80px 80px 0;

  h2{

    font-size: 32px;
    line-height: 42px;
    margin-bottom: 30px;
  }

  

  h2.mobile-title{
   // display: none;
  // font-family: 'Jost', system-ui, sans-serif;
   //   text-transform: uppercase;
      margin-bottom: 0;
  }

  h2.availability{
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
  }

  p{
    color: white;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
  
}

.book-info.lefty{
  align-self: flex-start;

  h2.other{

    font-family: 'Jost', system-ui, sans-serif;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 100;
    
  

    span.author-span{
      white-space: nowrap;

    }
  }

  .slide-link{
    color: green;
  }
}

.book-description > div{
//  position: absolute;
 // top: 50%;
//  left: 50%;
 // transform: translateY(-50%);
}

.book-description.lefty > div{
 // position: absolute;
 // top: 50%;
 // left: 0;
 // transform: translateY(-50%);
}



.all-links{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #555066a8;
  border-radius: 14px;
  margin-top: 40px;
  padding: 10px 20px;

  .store-links{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
     flex-wrap: wrap;

      

      .preorder{
   
        font-size: 20px;
        color: #f4fc90;
        margin: 0;
        line-height: 24px;
        /* line-height: 40px; */
        min-width: 40%;
        text-align: center;
    
      }
  }
  .explore-links {
      width: 100%;
      margin: 20px 0;
      text-align: center;

  }
}


.buylink-group{
 

  position: relative;
    width: 100%;
    min-width: 270px;
    max-width: 420px;
 // top: 0;
 // left: 0;
 // border:1px solid white;
 // height: 100%;

  ul{
    padding: 0;
    margin: 6px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    li{
      list-style-type: none;
    }
  }

  .order-text{
    display: inline-block;
  }

  .buylink{
   // margin: 10px;
   // max-width: 40px;
    border-radius: 4px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    display: inline-block;

    img{
        width: 100%;
    }

    
  }
}

.intro-block{

  width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  img{
    width: 50%;
  }

  .social-links{
    p{
          color: white;
          font-size: 14px;
          margin-left: 18px;

          a{
            text-decoration: none;
            padding: 0 4px;
            color: #F4FF90;

            &:hover{
              color: #3d236b;
            }
          }
    }
  }

}
.content-block{
  width: 60%;
}

.buttonContainer{
  position: absolute;
  left:0;
  bottom: 0;
  pointer-events: none;


  a{
    cursor: pointer;
    pointer-events: all;
  }
}

.printable{
  //box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  background-color:white;
  min-width: 400px;
  min-height: 80vh;//470px;//80vh;
  border-radius: $rounded-size;
  display:flex;
  justify-content: center;
  align-items:center;
  cursor: pointer;
}

.printable .buttonContainer{
  top: 30%;
  left: -50px;
  display: flex;
  flex-direction: column;
}

.roundButton{
  width: 80px;
  height: 80px;
  background-color: purple;
  border-radius: 50%;
  border: 0 solid transparent;
  background-repeat: no-repeat;
  background-position: center;
  margin: 4px;
  display: inline-block;
  cursor: pointer;

  &:hover, &:focus {
    transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
    animation: gelatine 0.5s 1;
  }

  &.downloadBtn{
    background-color: #574A7E;
    background-image: url(/assets/images/download-arrow.svg);
   // background-size: contain;
    
  }

  &.playBtn{
    background-color: #1E7ABA;
    background-image: url(/assets/images/play-btn.png);
    background-size: 40%;
    background-position: 55% 50%;
    
  }
  &.playBtn.secondBtn{
    background-color: #ffd800;
    background-image: url(/assets/images/play-btn.png);
    background-size: 40%;
    background-position: 55% 50%;
    
  }

  &.playBtn.thirdBtn{
    background-color: orange;
    background-image: url(/assets/images/play-btn.png);
    background-size: 40%;
    background-position: 55% 50%;
    
  }

  &.arrowLeft{
    background-color: #1E7ABA;
    background-image: url(/assets/images/arrow-leftt.svg);
    background-size: 40%;
    background-position: 55% 50%;
  }

  &.arrowRight{
    background-color: #1E7ABA;
    background-image: url(/assets/images/arrow-right.svg);
    background-size: 40%;
    background-position: 55% 50%;


  }
  &.printBtn{
    background-color: #ffd800;
    background-image: url(/assets/images/Print-icon.svg);
   // background-size: contain;
    
  }

  &.plusBtn{
    background-color: orange;
    background-image: url(/assets/images/plus.svg);
    background-size: 40%;
   // background-size: contain;
    
  }
}
.more-links{

  width: 100%;
  box-sizing: border-box;
  padding: 10px;

  button{
    padding: .5rem 1rem;
    padding-right: 1rem;
    cursor: pointer;
    font-family: 'Jost', system-ui, sans-serif;
    font-weight: 500;
    color: white;
    background: none;
    border: 1px solid white;
    border-radius: 4px;
    letter-spacing: .5px;
    font-size: 14px;
    margin: 10px 5px;
    z-index: 1;
    position: relative;

    &.large-btn{
      font-size: 24px;
     // width: 100%;
    }


    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 0;
      top: 0;
      left: 0;
      z-index: -1;
      background: darken($waypurple,20%);
      transition: all 0.3s ease-out;
      border-radius: 4px;
      opacity: 0;
    }
    
    &:hover {
      color: #fff;
    }
    &:hover:after {
      opacity: 1;
      top: auto;
      bottom: 0;
      height: 100%;
    }
    &:active {
      top: 2px;
    }
    

  }
}

.glass{
  
  position: relative;

  &::before{
    content:'';
    position: absolute;
    top: 0;
    left: -50%;
    width: 200%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: -1;

  }
}
/*
.glass {
  position: relative;
  background-color: white;
}

.glass::before {
  z-index: -1;
  display: block;
  position: absolute;
  content: ' ';
  -webkit-filter: url('#blur');
  filter: url('#blur');
  -webkit-filter: blur(5px);
  filter: blur(5px);
  background-size: cover;
  opacity: 0.5;
}
*/

.simple-trans-main{
  position: absolute;
  width: 100%;
  height: 100%;
}

.simple-trans-main > div {
  //cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 800;
  font-size: 25em;
  will-change: transform;//, opacity;
  -webkit-transform:translate3d(0,0,0);
 // text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
}

.angry{
  background-image: url('/assets/images/cursor/characters-angry-300.png');
  background-size: contain;
}

.lonely{
  background-image: url('/assets/images/cursor/characters-hallee-v2-300.png');
  background-size: contain;
}

.strongest{
  background-image: url('/assets/images/cursor/characters-worried-300.png');
  background-size: contain;
}



.worried{
  background-image: url('/assets/images/cursor/characters-worried-300.png');
  background-size: contain;
}

.mad{
  background-image: url('/assets/images/cursor/characters-mad-300.png');
  background-size: contain;
}

.sad{
  background-image: url('/assets/images/cursor/characters-sad-300.png');
  background-size: contain;
}
.jealous{
  background-image: url('/assets/images/cursor/characters-jealous-300.png');
  background-size: contain;
}

.modal-container{
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color: rgba(255,255,255,.2);
  display:flex;
  justify-content: center;
  align-items:center;
  z-index: 999999999;
}

.modal{
  position: absolute;
  min-width: 400px;
  min-height: 470px;//80vh;
 // background-color:white;
  color: black;
  display:flex;
  justify-content: center;
  align-items:center;
  /*
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: 700px;
  */
 
}

.mobile-title{

 // display: none;
}
.react-pdf__Page{
  position: relative;
}
.react-pdf__Page__annotations.annotationLayer{
  position: absolute;
 // background-color: rgba(255, 165, 0, 0.5);
  top:0;
  left:0;
  width:100%;
  height:100%;

  section{
    position: relative;
    width: 100% !important;
    left: 0 !important;
    transform: translateY(50px) !important;
   // top: auto !important;
   // margin: 2px 0;
   // height: 60px !important;

   
    top: 0 !important;
    margin: 2px 0;
    height: 10% !important;
   // border: 1px solid red;

    &:nth-of-type(7) {
     // background-color: orange;
      transform: translate(0px,120px) !important;
      
    }

    &:last-of-type {
     // background-color: rgba(23, 175, 136,0.5);
      transform: translate(0px,0px) !important;
    }

   

    @media (max-width: 860px) {

    //  transform: translateY(25px) !important;
      top: 5% !important;

      &:nth-of-type(7) {
       // background-color: orange;
        transform: translate(0px,125px) !important;
        
      }
  
      &:last-of-type {
      //  background-color: rgba(23, 175, 136,0.5);
        transform: translate(0px,-45px) !important;
      }
  }

  @media (max-width: 580px) {

    transform: translateY(25px) !important;
    top: 5% !important;

    &:nth-of-type(7) {
     // background-color: orange;
      transform: translate(0px,85px) !important;
      
    }

    &:last-of-type {
     // background-color: rgba(23, 175, 136,0.5);
      transform: translate(0px,-25px) !important;
    }
}

    a{
      width: 100% !important;
      height: 100% !important;
    // background-color: rgba(255, 255, 0, 0.5);
      display: block;
    }
  }
}
/*
.react-pdf__Document{
  border: 1px solid purple;

  &:hover{
    .page-controls{
      opacity: 1;
    }
  }

}
*/
.page-controls{
  position: absolute;
  bottom: -20px;
  left: 50%;
  background: white;
  opacity: 1;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  border-radius: 4px;
  visibility: visible;

  button{
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
  }

  span{
    font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
    color: black;
  }
}
/// pdf print specs....

@media print {
  body * {
    visibility: hidden;
  }

  body{
    width:100%;
    height:100%;
  }

  @page {
    size: 8x10 portrait;
    max-height:100%; 
    max-width:100%;
}


  .modal{
    left:0;
    top:0;
    transform: translate(0,0);
  }

  .printable, .printable * {
    visibility: visible;
  }

  .printable {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    .roundButton{
      display: none;
      //visibility: hidden;

    }

    .page-controls{
      display: none;
      visibility: hidden;
    }
  }
  img { width: 100%; margin: 0; padding: 0; }

  .not-printable{
    visibility: hidden;
  }
}




text {
  font-size: 32px;
  font-family: sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 10px;
  fill: black;
}

.insta-gallery{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
// border: 2px solid yellow;

@media (max-width: 580px) {
    position: relative;
  //  border: 1px solid red;
  //  background: azure;
}

  .insta-panel{
    height: 445px;
   // border: 1px solid red;
        display: flex;
    justify-content: center;
    align-items: center;
    background: #e1edf9;

   // width: auto;
//    background: green;
  //  height: 80vh;
 //   aspect-ratio: 3/4;
   // margin: 0 20px;

   &:hover{
     cursor: pointer;

   }

   &.image{

      .insta-icon{
        bottom: 15px;
      }
   }

   &.video{

        .insta-btn{

          width: 50px;
            height: 50px;
          // background-color: red;
            position: absolute;
            bottom: 15px;
            right: 10px;
            background-image: url(/assets/images/play-btn.png);
            background-size: 40%;
            background-position: 55% 50%;
            background-repeat: no-repeat;
            box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.35);
            background-color: orange;
            border-radius: 30px;


        // transition: opacity .3s ease-out;
        // opacity: 0;

          &:hover{
        //   opacity: 1;
          }
        
      /*
          &::after{
            content:'';
            width: 60px;
            height: 60px;
          // background-color: red;
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-image: url(/assets/images/play-btn.png);
            background-size: 60%;
            background-position: 55% 50%;
            background-repeat: no-repeat;
            box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.35);
          }
          */

        }

        &:hover{

          .insta-btn{
            background-color: #ffd800;
          }
        }
   }

    figure{
    //      width: 100%;
    //  height: 100%;
      padding: 0;
      margin: 0;
    //  display: flex;

      img{
        width: 100%;
      }

       video{
        object-fit: cover;
        width: 100%;
        height: 100%;
       }
    }

    &.message{

      background-color: #2456ab;



      h2{
        margin-top: 60px;
        width: 100%;
        font-size: 20px;
        padding: 20px;
        line-height: 32px;
      }

      &:hover{
        h2{
          color: #FFE70E;
        }
      }
    }
  }


}

.layout {
  width: 100%;
  height: 100vh;
  overflow: hidden;
 // background: #fff;
 // border: 3px solid yellow;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr 120px;
  align-items: center;

  .shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  
  }
  .absolute {
    position: absolute;
}

.justify-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}
.rounded{
  border-radius: 15px;
  overflow: hidden;
}
.inner-height{
  display: flex;
  height: fit-content;
}


.flex {
  display: flex;
}
.bg-cover {
  background-size: cover;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-center {
  background-position: center;
}


}

.rainbow-panel{
  background-image: url(/assets/images/rainbow.svg);
// background-color: blue;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: 820px){


      flex-direction: column;
    
  }

}

.bsc-intro-panel{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  transition: opacity 0.3s ease-out;
  padding: 0 40px;

  @media (max-width: 580px) {

    padding: 0 20px;
  }

  &.fadein{
    opacity: 1;
  }

  &.fadeout{
    opacity: 0;
  }

  h2{
    text-align: left;
    width: 570px;
    font-size: 55px;


    @media (max-width: 580px){
      width: 100%;
      margin: 0 auto;
 
      margin-bottom: 30px;
      font-size: 35px;
      line-height: 44px;
      text-align: center;
    }

    @media screen and (min-width: 821px) and (max-width: 1280px) { 
      font-size: 4vw;
      line-height: 4.5vw;
       width: 85%;
   }
  }
}

.bsc-callout{
  width: 420px;
  padding: 20px 40px;
  border-radius: 20px;
  background-color: #FFE70E;

  @media (max-width: 820px) {
      width: 100%;
      padding: 20px 20px;
  }



@media screen and (min-width: 821px) and (max-width: 12804px) { 
  width: 85%;
  padding: 20px 20px;

}
  
  h4{
    color: #4395CF;
    text-align: center;
    margin: 0;
    font-size: 22px;

    @media (max-width: 580px) {
     font-size: 20px;
     margin: 0 0 10px;
    }
   
  }

  a{
    color: #FA4545;
    text-decoration: underline;
    text-align: center;
    width: 100%;
    display: block;
    font-family: 'kyri_kapsregular';
    font-size: 22px;

    @media (max-width: 580px) {
    font-size: 20px;
    }
  }
}

.bsc-winners-grid{
  /*
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  
 // //grid-gap: 1rem;
  row-gap: 10px;
  column-gap: 1rem;
  grid-template-rows: max-content;
*/
      display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

  a.clickable{
  // border: 3px solid yellow;
    width: 23%;
    margin: 2px 4px;
    min-width: 150px;

    img{
      width: 100%;
      height: 100%;
    }
  }
}

.bsc-rules-container{
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 960px;
  margin: 0 auto;
  padding: 60px 40px;

  @media (max-width: 580px) {
    padding: 40px 20px;
  }

  h2{
    font-size: 50px;

    @media (max-width: 580px) {
     font-size: 32px;
     line-height: 42px;
    }

  

    @media (max-width: 1280px) {
      font-size: 32px;
      line-height: 42px;
     }

  }

  p{
    color: white;
  }sc


  .bsc-date-blocks{
    display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 580px) {
    flex-direction: column;
  }

    .date-block{
      background-color: #FFE70E;
      padding: 30px 40px;
      border-radius: 20px;

      @media (max-width: 580px) {
        width: 100%;
        margin: 10px 0;
        padding: 20px 30px;
      }

      h3{
        color: #4395CF;
        text-align: center;
        font-family: 'kyri_kapsregular';
        font-size: 28px;
        line-height: 30px;
        margin: 0;

        @media (max-width: 580px) {
          font-size: 32px;
          line-height: 34px;
          width: 100%;
        }
      }

      p{
        color: #4395CF;
        text-align: center;
        font-size: 16px;
        margin-top: 0;

        @media (max-width: 580px) {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }


  }

  h3{
    font-family: 'kyri_kapsregular';
    font-size: 28px;
    line-height: 30px;
    margin: 40px 0 30px;

    @media (max-width: 580px) {
        margin: 20px 0 40px;
        text-align: center;
        width: 80%;
    }
  }

  .bsc-rules{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center; 

    @media (max-width: 580px) {
      flex-direction: column;
      padding: 0 20px;
    }

    .rule-block{
     // border: 1px solid red;
      width: 25%;
      height: 200px;
      position: relative;
      transform: translateX(10px);

      @media (max-width: 580px) {
        width: 100%;
      }

      &.one::before{
        content: "1.";
        position: absolute;
        font-family: "kyri_kapsregular";
        font-size: 20px;
        top: 0;
        left: -18px;

 


      }

      &.two::before{
        content: "2.";
        position: absolute;
        font-family: "kyri_kapsregular";
        font-size: 20px;
        top: 0;
        left: -23px;

    


      }

      &.three::before{
        content: "3.";
        position: absolute;
        font-family: "kyri_kapsregular";
        font-size: 20px;
        top: 0;
        left: -23px;

       

      }

      h4{
        margin: 0 0 10px;
        height: 50px;

        @media (max-width: 580px) {
          font-size: 24px;
        }

      }

      p{
        font-size: 18px;
        line-height: 22px;

        @media (max-width: 580px) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      a{
        color: #FFE70E;
       text-decoration: none;
      }
    }
  }

  .bsc-fine-print{
    background-color: #209D7C;
    padding: 40px;
    border-radius: 20px;
    margin-top: 40px;
    width: 80%;
    
    p{
      font-size: 14px;;
      color: #A5FFF4;
      text-align: center;
      margin: 0;
    }
  }

}

@media (min-width: 640px) {
  .layout {
    width: 100vw;
    height: 812px;
  }

  
}



.disable-select {
  touch-action: none;
  /* cursor: grab; */
  z-index: 10000;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header {
  z-index: 999;
  grid-column: 1/2;
  grid-row: 1/2;
}

.content {
  grid-column: 1/2;
  grid-row: 1/4;
}
.nav {
  z-index: 999;
  grid-column: 1/2;
  grid-row: 3/4;
}

.backdrop {
  height: 565px;
}

.shadow {
  box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.35);
}

.text-title-main {
  color: #ffffffcc;
}
.text-title-sub {
  color: #cececeaa;
}

.transform-self-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.transform-self-top-center {
  left: 50%;
  top: 0;

  transform: translate(-50%, -50%);
}

.bg-details {
  background-color: #232323;
}

.separator {
  border-left: 1px solid #626262;
  border-right: 1px solid #626262;
}

.prev-btn {
  //background-color: black;
  background-color: rgb(214, 255, 11);
  padding: 4px;
  position: absolute;
  bottom: 20px;
  left: calc(50% - 100px);
  z-index: 20;
  width: 60px;
  height: 60px;

  @media (max-width: 580px) {

    left: 0;
    bottom: 45%;
   // transform: translateY(50%);
  }
 
}

.next-btn {
  background-color: rgb(214, 255, 11);
  padding: 4px;
  position: absolute;
  bottom: 20px;
  //right: 50px;
  z-index: 20;
  width: 60px;
  height: 60px;

  left: calc(50% - 10px);

  @media (max-width: 580px) {


    left: auto;
    right: 0;
    bottom: 45%;
   // transform: translateY(50%);
  }

}





@keyframes gelatine {

  from,to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
  from,to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}


/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
 .dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #F4FF90;
  color: #F4FF90;
  box-shadow: 9999px 0 0 0 #F4FF90;
  animation: dotFalling 1s infinite linear;
  animation-delay: .1s;
}

.dot-falling::before, .dot-falling::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #F4FF90;
  color: #F4FF90;
  animation: dotFallingBefore 1s infinite linear;
  animation-delay: 0s;
}

.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #F4FF90;
  color: #F4FF90;
  animation: dotFallingAfter 1s infinite linear;
  animation-delay: .2s;
}

@keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 #F4FF90;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 #F4FF90;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 #F4FF90;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@media (min-width: 581px) and (max-width: 1023px) {
  /*
  .jumbo {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate3d(-30%, -40%, 0);
   
  
    /////
  }
  */
}

@media (max-width: 699px) {
  .book-info{
    width: 100%;
    padding: 12px;
  }
}


/// mobile starts here...
@media (max-width: 580px) {


  .cursorBox{
    .cursorHolder{

      .cursorCircle{
        margin-left: 0;

      }

      .character{

      }
    }
  }


  .mycursor{
    overflow: hidden;
    left: 40px !important;
    bottom:40px !important;
    height:60px !important;
    width:60px !important;
    transform: scale(1) !important;
  }
  .word-balloon{
    bottom: 75px;
    opacity: 1;
    top: auto !important;
    left: 80px !important;
    transform: scale(.85);
  }
  .tail,
  .tail-shadow{
    transform: rotate(50deg) scaleY(1);
  }
  .logo {
    width: 70px;
    height: 70px;
    top: 1.5rem;
    left: 2rem;
  }
  .menu.right {
    top: 2rem;
    right: 2rem;
  }
  .jumbo{
    /*
    width: 80vw;
    top: 43%;
    left: 35%;
    min-width: 200px;
    */
    position: relative;
    top: unset;
    left:unset;
    min-width: unset;
    max-width: 400px;
    width: 100%;
    transform: none;

    & > div{
      width: 100% !important;
      height: auto !important;
    }


    .main-logo{
      height: 100%;
      width: 100%;
    }
  }

  .jumbo h1 {
    font-size: 46px;
    line-height: 46px;
    letter-spacing: 26px;
    margin-left: 26px;
  }

  .wide-panel{
   // min-height: 70vh;
 //   transform: scale(.65);
   // height: 100px;
   // margin-bottom: 100px;
  }

  .wide-panel.glass{
   // margin-top: 100px;
  //  padding-bottom: 200px;
   // border: 1px solid orange;
   // height: 200vh;
  }

  .video-holder{
   
    position: relative;
  width: 100%;
  padding-bottom: 56.25%;

    iframe{
     // width: 100%;
      position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
      
    }
  }

  .roundedVideo{
    transform: scale(.9,.9);
    position: relative;
    width: 100%;
  }

  .roundedVideo.playing{
    transform: scale(.9,.9);
  }



  .card-holder{
    width: 90vw;
    height: 100%;

    .card{
      background-size: 115%;
      max-width: 280px;
      max-height: 450px;

    }

  
  }

  .book-info{
    width: 100%;
    padding: 12px;
  }

  .intro-block{
    width: 100%;
    margin: 30px 0;
  }

  .content-holder{
    height: 234px;
    padding: 0 10px;
  }

  .content-holder.bsc{
    display: flex;
    flex-direction: column;
  
    h4{
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .image-holder{
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .img-holder-bsc{
    display: flex;
    align-items: center;
  }

  .content-block{
    width: 90%;
   // margin-bottom: 100px;
  }

  .book-info{
    padding: 0;

    h2{
      font-size: 22px;
      line-height: 34px;
    }

    h2.mobile-title{
     // margin-bottom: 0px;
    //  font-size: 28px;
    //  display: block;

      
    }

    p{
      font-size: 14px;
    line-height: 24px;
    }
  }

  .book-description > div{

    left: 0;

  }

  .buylink-group{

      width: 100%;
  }

  .glass{
    
  //  padding-bottom: 360px;
    flex-direction: column;

    transform: scale(0.8) translateY(-90px);
    transform-origin: top;
    height: 140vh;
 //   margin-top: 100px;
   // height: 160vh;
  }

  .more-links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;

    a{
      width: 100%;
    }

    button{
      width: 100%;
    margin: 5px 0;
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */

    min-height: 40px;
    outline: none;
    padding: .4rem .25rem;
    }
  }

  .modal{
    min-width: 300px;
    min-height: 300px;
    transform: scale(.95);

    .printable{
      min-width: 300px;
      min-height: 300px;
      
    } 
  }

  .scrollArea.grid-mode{
    //display: none;
  
    .gridSpacer{
      display: block;
     // height: 380vh !important;
    }
  
    .domContent{
      display: none;
    }
  
    .spacer{
      height: 50vh !important;
    }
  }

  .spacer{
       height: 750vh !important;
     }


.wide-panel.book-song-panel{

  padding: 0px;
  // height: auto;

   .image-holder{
    // border: 10px solid yellow;
     max-width: inherit;
     height: auto;
     aspect-ratio:auto;
   }

  .content-holder{
    flex-direction: column;
    padding: 50px 20px;
    height: auto;

    .book-song-headline{
      width: auto;
      padding: 10px 0;
      //margin: 0 40px;

        h2{
          font-size: 1.4em;
          line-height: 1.2em;
          text-align: center;
          margin: 0 0 20px 0;

        }
    }

    .book-song-info{
      width: auto;
      padding: 10px;
      background-color: aqua;
      //margin: 0 40px;

      p{
        font-size: 1em;
      }

      
    }

   
  }
}

.modal-container{
  padding-bottom: 80px;
}

.printable .buttonContainer{
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  bottom: -140px;
}

}


@media (max-height: 600px) {

  .word-balloon{
    bottom: 70px;
    opacity: 1;
    top: auto !important;
    left:75px !important;
    transform: scale(.75);
  }
}